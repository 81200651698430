import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter as Router } from 'react-router-dom';  // Import BrowserRouter
import ReactGA from 'react-ga4';  // Import ReactGA

library.add(fas);

// Initialize Google Analytics with your GA4 Measurement ID
ReactGA.initialize('G-94DJVPGQ7T');
ReactGA.send('pageview');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router> {/* Wrap App in Router */}
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
