import React, { useState, useEffect } from "react";
import "./Mindmap.css";
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewSet from "./NewSet";
import api from "../../services/api";
import TopBar from './TopBar';
import MarkmapComponent from "./Markmap";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




const Mindmaps = ({ navPaneExtended, toggleNavPane, checkAuth, triggerRefetch }) => {
    const [documents, setDocuments] = useState([]);

    const [mindmaps, setMindmaps] = useState([]);


    const [selectedSetTitle, setSetTitle] = useState("");
    const [selectedSet, setSelectedSet] = useState(false);
    const [selectedMindmap, setSelectedMindmap] = useState(undefined)
    const [animate, setAnimate] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({});
    const [markdownContent, setMarkdownContent] = useState('');
    const [thumbnails, setThumbnails] = useState({})



    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [result, setResult] = useState(null);


    const [renameValue, setRenameValue] = useState({});
    const [workflowRename, setWorkflowRename] = useState({})
    const [deletingWorkflow, setDeleteingWorkflow] = useState({})
    const [itemDelete, setItemDelete] = useState({})
    const [flashcards, setFlashcards] = useState([]);
    const [menuVisible, setMenuVisible] = useState({});

    const getMindmaps = async () => {
        let response = await api.get("/mindmap-sets");
        console.log(response.data);
        setMindmaps(response.data);

        response.data.forEach((mm) => {
            getThumbnails(mm.mindmap_set_id);
        });
    }

    const toggleMenu = (itemId) => {
        console.log(itemId)
        console.log(menuVisible)
        setMenuVisible((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleRenameChange = (itemId, event) => {
        // Update the input value for the specific workflow
        let fs = mindmaps.filter(fs => itemId === fs.mindmap_set_id)
        console.log(fs)
        setRenameValue((prevData) => ({ ...prevData, [itemId]: event.target.value }));
    };


    const toggleRename = (fcId) => {
        setMenuVisible(false);

        setRenameValue((prevState) => ({
            ...prevState,
            [fcId]: mindmaps.find(e => e.mindmap_set_id === fcId).title
        }))
        setWorkflowRename((prevState) => ({
            ...prevState,
            [fcId]: true
        }))
    }

    const deleteItem = (itemId) => {
        console.log(itemId);
        setItemDelete({ ...itemDelete, [itemId]: true })

    }

    const deleteWorkflowCancel = (itemId) => {
        setItemDelete({ ...itemDelete, [itemId]: false });
    }



    const deleteWorkflowConfirm = async (itemId) => {
        setDeleteingWorkflow((prevState) => ({
            ...prevState,
            [itemId]: true
        }))

        let response = await api.post(`/delete-mindmap-set/${itemId}`);
        console.log(response);
        setDeleteingWorkflow((prevState) => ({
            ...prevState,
            [itemId]: false
        }))

        getMindmaps();



    }


    const setWorkflowName = async (fsId) => {

        setWorkflowRename((prevState) => ({
            ...prevState,
            [fsId]: false
        }))

        //  console.log(workflows)

        setMindmaps((prevState) =>
            prevState.map((fs) =>
                fs.mindmap_set_id === fsId
                    ? { ...fs, title: renameValue[fsId] } // Update the name if the ID matches
                    : fs // Keep the workflow unchanged if the ID doesn't match
            )
        );

        const response = await api.post(`/rename-mindmap-set/${fsId}`, {}, {
            params: { 'new_name': renameValue[fsId] }
        })


    }





    const getThumbnails = async (mmId) => {
        // Start loading indicator for the specific flashcard set ID
        setLoading((prevLoading) => ({
            ...prevLoading,
            [mmId]: true,
        }));

        let imageUrl = undefined;

        try {
            // Make an API call to fetch the thumbnail
            let response = await api.get(`/mindmap-set-thumbnail/${mmId}`, { responseType: 'blob' });

            // If the API provides a response with a blob, convert it to an image URL
            imageUrl = URL.createObjectURL(response.data);
        } catch (error) {
            // If there's an error (like no thumbnail), set the default image from the public folder
            imageUrl = '/bookset.jpg';  // Ensure this image is present in your public folder
        }

        // Update the thumbnails state with the new image URL (or default image)
        setThumbnails((prevData) => ({
            ...prevData,
            [mmId]: imageUrl,
        }));

        // Stop the loading indicator for the specific flashcard set ID
        setLoading((prevLoading) => ({
            ...prevLoading,
            [mmId]: false,
        }));
    };


    useEffect(() => {
        api.get("/user-documents").then((response) => {
            console.log('documents')
            console.log(response);
            setDocuments(response.data);
        });
        getMindmaps();


    }, []);

    const openNewWorkflowModal = () => {
        dispatch(openModal({
            component: NewSet,
            props: { existingDocuments: documents, createSetapi: '/create-mindmaps', title: 'Mindmap', callBack: getMindmaps }
        }));
    };


    const handleContentChange = (updatedContent) => {
        setMarkdownContent(updatedContent);
    };


    const handleSetClick = async (setId) => {
        // Dummy data for flashcards in the selected set
        let response = await api.get(`/mindmap-set/${setId}`)
        console.log('mindmap response')
        console.log(response.data)



        setSelectedSet(setId);
        setSelectedMindmap(response.data[0])



        setAnimate(true); // Trigger the animation


    };


    const handleBackToSets = () => {
        setAnimate(false); // Reverse the animation
        setSelectedSet(null);
        setSelectedMindmap(undefined);
    };





    return (
        <div className="mp-container">
            <TopBar
                navPaneExtended={navPaneExtended}
                toggleNavPane={toggleNavPane}
                checkAuth={checkAuth}
                title="Mindmaps"
                newBtnClick={mindmaps.length > 0 ? openNewWorkflowModal : undefined}
                triggerRefetch={triggerRefetch} />

            {mindmaps.length === 0 && <div className="flc-empty">

                <div className="btn feedback" onClick={openNewWorkflowModal}>Create a set</div>

            </div>}

            {mindmaps.length > 0 &&<div className="mp-internal">



                <div className={`mp-content ${animate ? "animate" : ""} ${selectedSet ? "set-selected" : ""}`}>
                    <div className="mp-sets">
                        {selectedSet && (
                            <div onClick={handleBackToSets} className="simple-btn">
                                &larr; Back to Sets
                            </div>
                        )}
                        <div className={`mp-sets-grid ${selectedSet ? "single" : ""}`}>
                            {mindmaps.map((mm) => (
                                <div
                                    key={mm.mindmap_set_id}
                                    className={`mp-card-item ${mm.mindmap_set_id === selectedSet ? "active" : ""
                                        }`}
                                    onClick={() => handleSetClick(mm.mindmap_set_id)}
                                    title={mm.title}
                                >
                                     <div className="mp-item-header">
                                        {workflowRename[mm.mindmap_set_id] &&
                                            (<>

                                                <input className='wf-rename'
                                                    value={renameValue[mm.mindmap_set_id]}
                                                    type="text"
                                                    onChange={(e) => { handleRenameChange(mm.mindmap_set_id, e) }}
                                                    onClick={(e) => { e.stopPropagation() }}

                                                />

                                                <FontAwesomeIcon
                                                    className='wf-rename-confirm'
                                                    icon='fa-solid fa-check'
                                                    onClick={(e) => {e.stopPropagation(); setWorkflowName(mm.mindmap_set_id)}}></FontAwesomeIcon>




                                            </>)}
                                        {!workflowRename[mm.mindmap_set_id] && <h3 className="mp-item-title">
                                            {mm.title}
                                        </h3>}



                                        <div className="wf-menu-container" onClick={(e) => e.stopPropagation()}>

                                            <FontAwesomeIcon icon='fa-solid fa-ellipsis-vertical'
                                                className='wf-menu-btn'
                                                onClick={(e) => {
                                                    toggleMenu(mm.mindmap_set_id)
                                                }}>
                                            </FontAwesomeIcon>



                                            <CSSTransition
                                                in={menuVisible[mm.mindmap_set_id]}
                                                timeout={300}
                                                classNames="menu-transition"
                                                unmountOnExit
                                            >
                                                <div className="item-menu" >
                                                    <ul>
                                                        <li onClick={(e) => { e.stopPropagation(); toggleRename(mm.mindmap_set_id) }}>
                                                            Rename
                                                        </li>
                                                        {!itemDelete[mm.mindmap_set_id] && <li onClick={() => deleteItem(mm.mindmap_set_id)}>Delete</li>}
                                                        {itemDelete[mm.mindmap_set_id] && <li className='confirm-delete'>Sure?
                                                            {!deletingWorkflow[mm.mindmap_set_id] && <div className="wf-delete-controls">
                                                                <FontAwesomeIcon
                                                                    className='wf-delete-control wf-delete-confirm'
                                                                    icon='fa-solid fa-check'
                                                                    onClick={() => deleteWorkflowConfirm(mm.mindmap_set_id,)}></FontAwesomeIcon>
                                                                <FontAwesomeIcon
                                                                    className='wf-delete-control wf-delete-cancel'
                                                                    icon='fa-solid fa-close'
                                                                    onClick={() => deleteWorkflowCancel(mm.mindmap_set_id,)}></FontAwesomeIcon>
                                                            </div>}
                                                            {deletingWorkflow[mm.mindmap_set_id] && <div className="loader"></div>}
                                                        </li>}
                                                    </ul>
                                                </div>

                                            </CSSTransition>
                                        </div>
                                    </div>

                                    <div className="mp-item-display">
                                        {loading[mm.mindmap_set_id] ? (
                                            <div className="loader"></div>  // Display loader while fetching the thumbnail
                                        ) : (
                                            <img
                                                src={thumbnails[mm.mindmap_set_id] || '/bookset.jpg'}  // Fallback in case the thumbnail is missing
                                                alt={mm.title}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {selectedSet && <div className="mp-set mindmap">
                        {selectedMindmap.content ? (
                            <MarkmapComponent markdownContent={selectedMindmap.content} onContentChange={handleContentChange} />
                        ) : (
                            <p>Loading mind map data...</p>

                        )}
                    </div>}

                </div>
            </div>}





        </div>
    );
};

export default Mindmaps;
