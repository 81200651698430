import { React, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import app from '../../services/api';
import './Upload.css';
import useWorkflows from '../../hooks/useWorkflows';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';


const Upload = ({ workflowId, workflowTitle, onUploadComplete, handleClose }) => {

    const [uploadProgress, setUploadProgress] = useState({});
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [innerWorkflowId, setWorkflowId] = useState(workflowId);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [youtubeLink, setYoutubeLink] = useState('');
    const navigate = useNavigate();



    useEffect(() => {
        if (innerWorkflowId && filesToUpload.length > 0) {
            uploadFiles();
        }
    }, [innerWorkflowId, filesToUpload]);

   
    const uploadFiles = async () => {

        setLoading(true);
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('file', filesToUpload[i].file);

            let params = new URLSearchParams();
            params.append('workflow_id', innerWorkflowId);

            if (files[i].fileType === 'youtube'){
                let tFile = files[i]
                let vId = tFile.file;
                try{
                    const response = await api.post('/upload-youtube', {'workflow_id': workflowId, 'video_id':vId})
                    console.log(response.data);
                }catch{
                  
                }
            }

            else{

            const formData = new FormData();
            formData.append('file', files[i].file);

            let params2 = new URLSearchParams();
            params2.append('workflow_id', innerWorkflowId);



            try {
                const response = await api.post('/upload', formData, {
                    params: params2,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true,
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prevProgress) => ({
                            ...prevProgress,
                            [i]: progress
                        }));
                    }
                });
                console.log('File uploaded:', response);
            } catch (error) {
                console.error('Error uploading file', error);
            }
        }
        }
        // triggerRefetch();
        setLoading(false);
        setFiles([]); // Clear the files after upload
        onUploadComplete();
        navigate('/w/workflow/' + innerWorkflowId);
        handleClose();
    };

 



    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setUploadProgress((prevProgress) => {
            const newProgress = { ...prevProgress };
            delete newProgress[index];
            return newProgress;
        });
    };

    const handleFileChange = (event) => {
        const allowedExtensions = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

        const newFiles = Array.from(event.target.files).filter(file => {
            // Check if the file type is allowed
            if (!allowedExtensions.includes(file.type)) {
                setErrorMessage('File extension not allowed')
                setTimeout(() => {
                    setErrorMessage(undefined);
                }, 5000);
                return false;
            }
            return true;
        }).map(file => ({
            file,
            name: file.name,
            fileType: 'file',
            size: (file.size / (1024 * 1024)).toFixed(2), // size in MB
            progress: 0
        }));

        // If valid files exist, update the state
        if (newFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }

        // Reset the input value to allow re-uploading the same file
        event.target.value = null;
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (workflowId === undefined) {
            let params = new URLSearchParams();
            params.append('workflow_title', workflowTitle);
            try {
                const response = await app.post('/create-workflow', {}, {
                    params: params,
                    withCredentials: true
                });
                console.log('Workflow created successfully');
                setWorkflowId(response.data.workflow_id);
            } catch (error) {
                console.error('Error creating workflow', error);
                return;
            }
        }

        setFilesToUpload(files); // Set the files to be uploaded
    };

    const extractYouTubeID = (url) => {
        const regex = /[?&]v=([^&]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    const addYoutube = async () => {

        const videoID = extractYouTubeID(youtubeLink);
        console.log(videoID);

        if (files.some(file => file.file === videoID)) {
            console.log('Video already exists in the list.');
            return;
        }

        try {
            let response = await api.get('/get-youtube-title', {
                params: {
                    video_id: videoID
                }
            })

            setFiles((prevFiles) => [...prevFiles, {
                file: videoID,
                name: response.data,
                size: 0, // size in MB
                fileType: 'youtube',
                progress: 0
            }]);
            console.log(response.data);
        } catch {
            setErrorMessage('Youtube video does not have transcripts enabled');
            setTimeout(() => {
                setErrorMessage(undefined);
            }, 5000);
        }

    }


    return (


        <form className='up-upload-form' onSubmit={handleSubmit}>
            {errorMessage && (
                <div className="error-box">
                    {errorMessage}
                </div>
            )}

            <div className='upload-area' >
                <div className='upload-btn' onClick={() => { fileInputRef.current.click() }}>
                    <FontAwesomeIcon icon='fa-solid fa-upload'></FontAwesomeIcon>
                    <h2>Upload</h2>
                </div>
                <input type="file"
                    onChange={handleFileChange}
                    className='file-input'
                    ref={fileInputRef}
                    multiple
                    hidden
                />
            </div>

            <div className="upload-link">
                <input type="text" placeholder='Paste a youtube link' value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} />
                <div className='submit' onClick={() => addYoutube()}><div className="submit-text">Submit</div></div>
            </div>
            <div className="staging-area">
                {files.map((file, index) => (
                    <div key={index} className="staged-file">
                        <div className="file-icon">
                            {file.fileType === 'file' && <FontAwesomeIcon icon='fa-solid fa-file' />}
                            {file.fileType === 'youtube' && <FontAwesomeIcon icon="fa-solid fa-video" />}
                        </div>
                        <div className="file-info">
                            <span className='filename'>{file.name}</span>
                            <span>{file.size} MB</span>
                            <progress value={uploadProgress[index] || 0} max="100" />
                            <button
                                type="button"
                                className="remove-btn"
                                onClick={() => handleRemoveFile(index)}
                            ><FontAwesomeIcon icon="fa-solid fa-times" />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <button className={`btn ${loading ? 'loading' : ''}`} type="submit" >{loading && <div className="loader"></div>} Upload</button>
        </form>


    )
}

export default Upload;