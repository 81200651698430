import { useState, useEffect } from 'react';

import api from '../services/api';


const useChatbot = () => {
    const [object_type, setObjectType] = useState(null);
    const [objectId, setObjectId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(true);



    // get all the messages
    const fetchMessages = async ({ objectId }) => {
        setLoading(true);
        try {
            const response = await api.get('/chat', {
                params: { object_id: objectId, object_type: object_type },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setLoading(false);          
            return response.data


        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    useEffect(() => {
        const loadMessages = async () => {
            if (objectId) {
                const messages = await fetchMessages({ objectId });
                if (messages) {
                    const tmpMessages = messages.map((message) => ({
                        text: message.text,
                        sender: message.user,
                    }));
                    setMessages(tmpMessages);
                }
            }
        };

        loadMessages();
    }, [objectId]);

    const sendMessage = async (message) => {
        try {
            const response = await api.post('/chat', {
                sender: 'user',
                text: message,
                object_type: object_type,
                object_id: objectId,
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            // Assuming the response data contains the new message
            const newMessage = response.data;
           
            setMessages((prevMessages) => [...prevMessages, {text: newMessage, sender: 'assistant'}]);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };





    const handleSendMessage = () => {
        if (inputValue.trim()) {

            setMessages((prevMessages) => [...prevMessages, { text: inputValue, sender: 'user' }]);
            sendMessage(inputValue);
            setInputValue('');
           

            // setTimeout(() => {
            //     setMessages((prevMessages) => [...prevMessages, { text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem aliquam odio nam. Quibusdam dolore minus illo beatae ex fuga necessitatibus odit unde facere, nulla, fugiat obcaecati aperiam dignissimos cumque amet.', sender: 'assistant' }]);
            //     console.log(messages)
            // }, 1000);

        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    }


    return {
        messages,
        setMessages,
        inputValue,
        setInputValue,
        handleSendMessage,
        handleInputChange,
        handleKeyPress,
        setObjectId,
        setObjectType,
        setLoading,
        loading,

    }

}

export default useChatbot;