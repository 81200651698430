import React, { useState, useEffect, forwardRef } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-h5-audio-player/lib/styles.css';
import './Podcast.css';
import AudioPlayer from 'react-h5-audio-player';
import { faMicrophoneLines, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
const apiUrl = process.env.REACT_APP_API_URL;

const Podcasts = forwardRef(({ className, objectId, objectType }, ref) => {
    const [documents, setDocuments] = useState([]);
    const [podcastStatus, setPodcastStatus] = useState({});
    const [loading, setLoading] = useState({});
    const [currentDocId, setCurrentDocId] = useState(null);
    const [audioUrl, setAudioUrl] = useState('');
    const [showPlayer, setShowPlayer] = useState(false);

    // Fetch documents when the component mounts
    useEffect(() => {
            fetchDocuments();
    }, [objectId, objectType]);

    const fetchDocuments = async () => {
        try {
            const response = await api.get(`/get-object-documents/${objectType}/${objectId}`);
            console.log(response.data)
            setDocuments(response.data);
            checkPodcastStatuses(response.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const checkPodcastStatuses = async (docs) => {
        const statusPromises = docs.map((doc) =>
            api
                .get(`/podcast-status/${doc.document_id}`)
                .then((response) => ({
                    docId: doc.document_id,
                    status: response.data.status,
                    progress: response.data.progress,
                }))
                .catch(() => ({ docId: doc.document_id, status: 'not_started', progress: 0 }))
        );

        const statuses = await Promise.all(statusPromises);
        const newPodcastStatus = {};
        statuses.forEach((status) => {
            newPodcastStatus[status.docId] = {
                status: status.status,
                progress: status.progress,
            };
            if (status.status === 'completed' || status.status === 'not_found') {
                setLoading((prevState) => ({
                    ...prevState,
                    [status.docId]: false,
                }));
            } else {
                setLoading((prevState) => ({
                    ...prevState,
                    [status.docId]: true,
                }));
                connectWebSocket(status.docId);
            }
        });
        setPodcastStatus(newPodcastStatus);
    };

    const connectWebSocket = (docId) => {
        const ws = new WebSocket(`${apiUrl}/ws/podcast/${docId}`);

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setPodcastStatus((prevState) => ({
                ...prevState,
                [docId]: { status: data.status, progress: data.progress },
            }));

            console.log(data)

            if (data.status === 'completed') {
                setLoading((prevState) => ({
                    ...prevState,
                    [docId]: false,
                }));
                fetchPodcastAndPlay(docId);
                ws.close();

            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            setLoading((prevState) => ({
                ...prevState,
                [docId]: false,
            }));
        };

        ws.onclose = () => {
            console.log('WebSocket connection closed');
            setLoading((prevState) => ({
                ...prevState,
                [docId]: false,
            }));
        };
    };

    const fetchPodcastAndPlay = async (docId) => {
        try {
            const response = await api.get(`/download-podcast/${docId}`, { responseType: 'blob' });
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const url = URL.createObjectURL(audioBlob);
            setAudioUrl(url);
            setShowPlayer(true);
            setCurrentDocId(docId);
        } catch (error) {
            console.error('Error fetching and playing podcast:', error);
        }
    };

    const handlePodcastClick = async (docId) => {
        const currentStatus = podcastStatus[docId]?.status;

        if (currentStatus === 'completed') {
            if (currentDocId !== docId) {
                fetchPodcastAndPlay(docId);
            } else {
                setShowPlayer(!showPlayer);
            }
        } else if (currentStatus === 'processing') {
            // Do nothing, already processing
        } else {
            // Start generation
            try {
                await api.post('/create-podcast', {}, {
                    params: { document_id: docId },
                });
                setPodcastStatus((prevState) => ({
                    ...prevState,
                    [docId]: { status: 'processing', progress: 0 },
                }));
                setLoading((prevState) => ({
                    ...prevState,
                    [docId]: true,
                }));
                connectWebSocket(docId);
            } catch (error) {
                console.error('Error creating podcast:', error);
            }
        }
    };

    // Clean up the object URL when the component unmounts or audioUrl changes
    useEffect(() => {
        return () => {
            if (audioUrl) {
                URL.revokeObjectURL(audioUrl);
            }
        };
    }, [audioUrl]);

    return (
        <div className={`${className} podcast-container`}>
            {documents.map((doc) => {
                return (
                    <div key={doc.document_id} className='pc-item' onClick={() => { handlePodcastClick(doc.document_id); }}>
                        {!loading[doc.document_id] ?
                            <div className='pc-icon'><FontAwesomeIcon icon={faMicrophoneLines} /></div> :
                            <div className='loader'></div>
                        }
                        <p>{doc.title}</p>
                    </div>
                );
            })}

            {/* CSSTransition for the audio player */}
            <CSSTransition
                in={showPlayer}
                timeout={300}
                classNames="audio-player-transition"
                unmountOnExit
            >
                <div className="audio-player-container">
                    <div className="audio-player-header">

                        <div className="audio-player-close" onClick={() => setShowPlayer(false)}>
                            <FontAwesomeIcon className='plr-close' icon={faTimes} />
                        </div>
                    </div>
                    <AudioPlayer
                        autoPlay
                        src={audioUrl}
                        onPlay={() => console.log("Playing")}
                        customAdditionalControls={[]}
                        customControlsSection={['MAIN_CONTROLS',]}
                        showJumpControls={true}
                        jumpForward={10}
                        jumpBackward={10}
                        onEnded={() => setShowPlayer(false)}
                        customProgressBarSection={['CURRENT_TIME', 'PROGRESS_BAR', 'DURATION']}
                    />
                </div>
            </CSSTransition>
        </div>
    );
});

export default Podcasts;
