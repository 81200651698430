import React, { useState, useEffect, useRef } from 'react';
import TopBar from './TopBar';
import DocumentViewer from './DocumentViewer';
import Chatbot from './Chatbot';
import NotesSection from './Notes';
import DocumentUpload from './DocumentUpload';
import Podcasts from './Podcast';
import './MainView.css';
import { CSSTransition } from 'react-transition-group';

const MainView = ({
  currentWorkflowId, 
  currentDocumentId, 
  navPaneExtended, 
  toggleNavPane, 
  checkAuth, 
  currentWorkflow,
  triggerRefetch,
}) => {
  const [notesVisible, setNotesVisible] = useState(false);
  const [podcastsVisible, setPodcastsVisible] = useState(false);
  const [workflowId, setWorkflowId] = useState(currentWorkflowId);
  const [documentId, setDocumentId] = useState(currentDocumentId);
  const [title, setTitle] = useState(null);
  const podcastsRef = useRef(null);

  const [objectType, setObjectType] = useState(null);
  const [objectId, setObjectId] = useState(null);

  useEffect(() => {
    setNotesVisible(false);
    setPodcastsVisible(false);
    if (currentWorkflowId) {
      setObjectId(currentWorkflowId);
      setObjectType('workflow');
    } else if (currentDocumentId) {
      setObjectId(currentDocumentId);
      setObjectType('document');
    }
  }, [currentWorkflowId, currentDocumentId]);

  const documentViewerRef = useRef(null);

  const handleReferenceClick = (refNumber) => {

    if (documentViewerRef.current) {

      documentViewerRef.current.scrollToPage(refNumber);
    }
  };


  



  return (
    <div className="main-view">
      <TopBar 
        navPaneExtended={navPaneExtended} 
        toggleNavPane={toggleNavPane} 
        checkAuth={checkAuth} 
        title={title}
        triggerRefetch={triggerRefetch}
      />
      <div className="bottom-section">
        <DocumentViewer 
          ref={documentViewerRef}
          className={`document-viewer ${(notesVisible | podcastsVisible) ? 'flex-third' : 'flex-half'}`}
          objectId={objectId} 
          objectType={objectType} 
          currentWorkflow={currentWorkflow} 
          setTitle={setTitle}
        />
        <Chatbot 
          className={`chatbot ${(notesVisible | podcastsVisible) ? 'flex-third' : 'flex-half'}`} 
          notesVisible={notesVisible}
          togglePodcasts={()=>{setNotesVisible(false); setPodcastsVisible(!podcastsVisible)}}
          toggleNotes={() =>{setPodcastsVisible(false); setNotesVisible(!notesVisible)}} 
          objectId={objectId} 
          objectType={objectType}
          onReferenceClick={handleReferenceClick}
        />
      
        <CSSTransition
          in={notesVisible}
          timeout={300}
          classNames="slide"
          unmountOnExit>

          <NotesSection className="notes-section flex-third"  objectId={objectId} objectType={objectType}/>
        </CSSTransition>

        <CSSTransition
          in={podcastsVisible}
          nodeRef={podcastsRef}
          timeout={300}
          classNames="slide"
          unmountOnExit
        >
          <Podcasts ref={podcastsRef} className="notes-section flex-third"  objectId={objectId} objectType={objectType}/>
        </CSSTransition>
      </div>
    </div>
  );
};

export default MainView;
