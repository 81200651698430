// src/components/MarkdownRenderer.jsx

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './MarkdownRenderer.css'; // Import CSS for styling

const MarkdownRenderer = ({ markdownText, onReferenceClick }) => {
  const processedMarkdown = markdownText.replace(/\[(\d+)\]/g, '[$1](#ref-$1)');
  return (
    <div className="markdown-container">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ node, href, children, ...props }) => {
            const match = href.match(/#ref-(\d+)/);
            if (match) {
              const refNumber = parseInt(match[1], 10);
              return (
                <span
                  className="reference-link"
                  onClick={(e) => {
                    e.preventDefault();
                    if (onReferenceClick) {
                      onReferenceClick(refNumber);
                    }
                  }}
                  {...props}
                >
                  {children}
                </span>
              );
            } else {
              // For other links, you might still want to render them as <a> or handle accordingly
              return <a href={href} {...props}>{children}</a>;
            }
          },
        }}
      >
        {processedMarkdown}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;
