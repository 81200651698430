import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import NavigationPanel from './NavigationPanel';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MainLayout.css';
import useWorkflows from '../../hooks/useWorkflows';
import NewWorkflow from './NewWorkflow';

const MainLayout = ({ 
  navPaneExtended, 
  toggleNavPane, 
  setCurrentWorkflowId, 
  currentWorkflowId,
  setCurrentDocumentId, 
  openWorkflowModal, 
  refetchTrigger,
  triggerRefetch,
  setNewDocumentWorkflowId,
  setCurrentWorkflow,
  setObjectType,
  setObjectId,
  workflows,
  setWorkflows,
  expandedWorkflows,
  toggleWorkflow }) => {

    

    const navigate = useNavigate();

    const location = useLocation();


    useEffect(() => {
      if (workflows && workflows.length > 0 && location.pathname === '/w') {
        const lastWorkflowId = workflows[0].id;
        navigate(`/w/workflow/${lastWorkflowId}`);
      } else if (workflows && workflows.length === 0 && location.pathname === '/w') {
        console.log('to new workflow')
        navigate('/w/new'); // Navigate to the NewWorkflow route
      }
    }, [workflows, navigate, location.pathname]);

  return (
    <div className="app">
      <div className='mobile-display'>
      <div className="displays">
        <FontAwesomeIcon icon="fa-solid fa-laptop" />
        <FontAwesomeIcon icon="fa-solid fa-computer" />
        <FontAwesomeIcon icon="fa-solid fa-tablet-screen-button" />
      </div>
        <div className="text">Please continue using <span className="ln-deductiv">deductiv</span> on your <span className="device">laptops</span>, <span className="device">tablets</span> and <span className="device">PCs</span> while we enhance your mobile experience </div>
      </div>
      <div className={`nav-panel ${navPaneExtended ? '' : 'collapsed'}`}>
        <NavigationPanel
          paneExtended={navPaneExtended}
          togglePane={() => { toggleNavPane(!navPaneExtended); }}
          setCurrentWorkflowId={setCurrentWorkflowId}
          currentWorkflowId={currentWorkflowId}
          setCurrentDocumentId={setCurrentDocumentId}
          workflows={workflows}
          setWorkflows={setWorkflows}
          expandedWorkflows={expandedWorkflows}
          toggleWorkflow={toggleWorkflow}
          toggleNewWorkflowModal={openWorkflowModal}
          refetchTrigger={refetchTrigger}
          triggerRefetch={triggerRefetch}
          setNewDocumentWorkflowId={setNewDocumentWorkflowId}
          setCurrentWorkflow={setCurrentWorkflow}
          setObjectType={setObjectType}
          setObjectId={setObjectId}
        />
      </div>

      <div className="rest">
        <Outlet />
      </div>

    </div>
  );
};

export default MainLayout;