import { react, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import './Founders.css'


const Founders = () => {

    return (
        <div className='founders'>
            <div className="header">
                <h1><span className="cap">reach out</span> </h1>
            </div>

            <div className="team">
                <div className='founder'>


                    <img className='icon' src="/aasim.jpg" alt="Aasim" />
                    <div className="description">
                        <div className='founder-name'><span className="bold-name">Aasim</span> Ahmed</div>
                        <div className='founder-position'>Co-founder and CEO</div>
                    </div>
                    <div className="contacts">
                        <a href="https://instagram.com/aasim.96"  target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/instagram.png' alt='instagram' /></a>
                        <a href="https://wa.me/971567094434" target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/whatsapp.png' alt='whatsapp' /></a>
                        <a href="mailto:aasim2347@gmail.com" target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/gmail.png' alt='Founder 3' /></a>

                    </div>


                </div>
                <div className='founder'>


                    <img className='icon' src="/kumail.jpeg" alt="Kumail" />
                    <div className="description">
                        <div className='founder-name'>Muhammad <span className="bold-name">Kumail</span></div>
                        <div className='founder-position'>Co-founder and CTO</div>
                        
                    </div>
                    <div className="contacts">
                        <a href="https://instagram.com/smkumaill"  target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/instagram.png' alt='instagram' /></a>
                        <a href="https://wa.me/971502758986" target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/whatsapp.png' alt='whatsapp' /></a>
                        <a href="mailto:smkumaill@gmail.com" target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/gmail.png' alt='Founder 3' /></a>

                    </div>
                    


                </div>
                <div className='founder'>


                    <img className='icon' src="/daniyal.jpeg" alt="Daniyal" />
                    <div className="description">
                        <div className='founder-name'><span className="bold-name">Danyal</span> Ahmed</div>
                        <div className='founder-position'>Head of Commercial</div>
                    </div>
                    <div className="contacts">
                        <a href="https://instagram.com/spotted_pilot"  target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/instagram.png' alt='instagram' /></a>
                        <a href="https://wa.me/447436975781" target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/whatsapp.png' alt='whatsapp' /></a>
                        <a href="mailto:Ahmed.danyal1999@gmail.com" target="_blank" rel="noopener noreferrer"><img className='social-icon' src='/gmail.png' alt='Founder 3' /></a>

                    </div>



                </div>
            </div>
        </div>
    )
}

export default Founders;