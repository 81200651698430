import React, { createContext, useContext, useState, useEffect } from 'react';

const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {

  const [objectType, setObjectType ] = useState(undefined) ;
  const [objectId, setObjectId] = useState(undefined);
  const [selectedWorkflow, setSelectedWorkflow] = useState(undefined);
  const [selectedDocument, setSelectedDocument] = useState(undefined);

  const value = {
    selectedWorkflow,
    setSelectedWorkflow,
    selectedDocument,
    setSelectedDocument,
    objectType,
    setObjectType,
    objectId,
    setObjectId

  };

  return (
    <DocumentContext.Provider value={value}>
      
      <div>
        {children}
      </div>
    </DocumentContext.Provider>
  );
};

export const useDocumentsApp = () => {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};

