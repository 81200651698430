import React, { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  // Fetch workflows from API
  useEffect(() => {
    // Fetch workflows and update state
  }, []);

  const [useOpenDyslexic, setUseOpenDyslexic] = useState(false);

  useEffect(() => {
    const fontPreference = localStorage.getItem('useOpenDyslexic');
    if (fontPreference) {
      setUseOpenDyslexic(JSON.parse(fontPreference));
    }
  }, []);

  const toggleFont = () => {
    setUseOpenDyslexic((prevState) => {
      const newState = !prevState;
      localStorage.setItem('useOpenDyslexic', JSON.stringify(newState));
      return newState;
    });
  };

  const value = {
    workflows,
    setWorkflows,
    selectedWorkflowId,
    setSelectedWorkflowId,
    selectedDocumentId,
    setSelectedDocumentId,
    useOpenDyslexic,
    toggleFont
    
  };

  return (
    <AppContext.Provider value={value}>
      {/* Wrap children with a div that conditionally applies the font class */}
      <div className={useOpenDyslexic ? 'open-dyslexic-font' : ''}>
        {children}
      </div>
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};

export const useSelectedItems = () => {
  const { workflows, selectedWorkflowId, selectedDocumentId } = useApp();
  
  const selectedWorkflow = workflows.find(w => w.id === selectedWorkflowId);
  const selectedDocument = selectedWorkflow?.documents.find(d => d.id === selectedDocumentId);

  return { selectedWorkflow, selectedDocument };
};