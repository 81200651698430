import React, { useState, useEffect } from "react";
import "./Quizzes.css";
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewSet from "./NewSet";
import api from "../../services/api";
import TopBar from './TopBar';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const Quizzes = ({navPaneExtended, toggleNavPane, checkAuth, triggerRefetch}) => {
    const [documents, setDocuments] = useState([]);
    const [quizSet, setQuizSet] = useState([]);
    const [quizzes, setQuizzes] = useState([]);


    const [selectedSetTitle, setSetTitle] = useState("");
    const [selectedSet, setSelectedSet] = useState(null);
    const [flippedCards, setFlippedCards] = useState({});
    const [animate, setAnimate] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({});
    const [thumbnails, setThumbnails] = useState({})

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [result, setResult] = useState(null);


    const [renameValue, setRenameValue] = useState({});
    const [workflowRename, setWorkflowRename] = useState({})
    const [deletingWorkflow, setDeleteingWorkflow] = useState({})
    const [itemDelete, setItemDelete] = useState({})
    const [flashcards, setFlashcards] = useState([]);
    const [menuVisible, setMenuVisible] = useState({});


    const getQuizzes = async () => {
        let response = await api.get("/quizzes");
        console.log(response.data);
        setQuizSet(response.data);
        response.data.forEach((qz) => {
            getThumbnails(qz.quiz_set_id);
        });

    }


    const toggleMenu = (itemId) => {
        console.log(itemId)
        console.log(menuVisible)
        setMenuVisible((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleRenameChange = (itemId, event) => {
        // Update the input value for the specific workflow
        let fs = quizSet.filter(fs => itemId === fs.quiz_set_id)
        console.log(fs)
        setRenameValue((prevData) => ({ ...prevData, [itemId]: event.target.value }));
    };


    const toggleRename = (fcId) => {
        setMenuVisible(false);

        setRenameValue((prevState) => ({
            ...prevState,
            [fcId]: quizSet.find(e => e.quiz_set_id === fcId).title
        }))
        setWorkflowRename((prevState) => ({
            ...prevState,
            [fcId]: true
        }))
    }

    const deleteItem = (itemId) => {
        console.log(itemId);
        setItemDelete({ ...itemDelete, [itemId]: true })

    }

    const deleteWorkflowCancel = (itemId) => {
        setItemDelete({ ...itemDelete, [itemId]: false });
    }



    const deleteWorkflowConfirm = async (itemId) => {
        setDeleteingWorkflow((prevState) => ({
            ...prevState,
            [itemId]: true
        }))

        let response = await api.post(`/delete-quiz-set/${itemId}`);
        console.log(response);
        setDeleteingWorkflow((prevState) => ({
            ...prevState,
            [itemId]: false
        }))

        getQuizzes();   


    }


    const setWorkflowName = async (fsId) => {

        setWorkflowRename((prevState) => ({
            ...prevState,
            [fsId]: false
        }))

        //  console.log(workflows)

        setQuizSet((prevState) =>
            prevState.map((fs) =>
                fs.quiz_set_id === fsId
                    ? { ...fs, title: renameValue[fsId] } // Update the name if the ID matches
                    : fs // Keep the workflow unchanged if the ID doesn't match
            )
        );

        const response = await api.post(`/rename-quiz-set/${fsId}`, {}, {
            params: { 'new_name': renameValue[fsId] }
        })


    }


    const getThumbnails = async (quizId) => {
        // Start loading indicator for the specific flashcard set ID
        setLoading((prevLoading) => ({
            ...prevLoading,
            [quizId]: true,
        }));
    
        let imageUrl = undefined;
    
        try {
            // Make an API call to fetch the thumbnail
            let response = await api.get(`/quiz-set-thumbnail/${quizId}`, { responseType: 'blob' });
            
            // If the API provides a response with a blob, convert it to an image URL
            imageUrl = URL.createObjectURL(response.data);
        } catch (error) {
            // If there's an error (like no thumbnail), set the default image from the public folder
            imageUrl = '/bookset.jpg';  // Ensure this image is present in your public folder
        }
    
        // Update the thumbnails state with the new image URL (or default image)
        setThumbnails((prevData) => ({
            ...prevData,
            [quizId]: imageUrl,
        }));
    
        // Stop the loading indicator for the specific flashcard set ID
        setLoading((prevLoading) => ({
            ...prevLoading,
            [quizId]: false,
        }));
    };

    useEffect(() => {
        api.get("/user-documents").then((response) => {
            console.log('documents')
            console.log(response);
            setDocuments(response.data);
        });
        getQuizzes();
    }, []);

    const openNewWorkflowModal = () => {
        dispatch(openModal({
            component: NewSet,
            props: { existingDocuments: documents, createSetapi: '/create-quizzes', title: 'Quiz', callBack: getQuizzes }
        }));
    };



    const handleSetClick = async (setId) => {
        // Dummy data for flashcards in the selected set
        let response = await api.get(`/quiz-set/${setId}`)
        console.log('quiz response')
        console.log(response.data)


        let setTitle = quizSet.find((set) => set.quiz_set_id === setId).title;
        setSetTitle(setTitle);
        setSelectedSet(setId);
        setQuizzes(response.data);
        setUserAnswers([]);
        setResult(null);
        setCurrentQuestionIndex(0);
        setAnimate(true); // Trigger the animation


    };


    const handleBackToSets = () => {
        setAnimate(false); // Reverse the animation
        setSelectedSet(null);
        setQuizzes([]);
        setResult(null);
        setUserAnswers([]);
        setCurrentQuestionIndex(0);


    };

    const handleAnswerSelection = (optionId) => {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[currentQuestionIndex] = optionId; // Store the selected option's ID
        setUserAnswers(updatedAnswers);
        console.log(userAnswers);
    };  

    const handleNextQuestion = () => {
        if (currentQuestionIndex < quizzes.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleFinishQuiz = () => {
        const correctAnswers = quizzes.filter(
            (quiz, index) =>
                quiz.options.find(
                    (option) => option.option_id === userAnswers[index] && option.is_correct
                ) // Check if the selected option is correct
        ).length;

        setResult(correctAnswers);

    };

    return (
        <div className="qz-container">
        <TopBar 
        navPaneExtended={navPaneExtended} 
        toggleNavPane={toggleNavPane} 
        checkAuth={checkAuth} 
        title="Quizzes" 
        newBtnClick={quizSet.length > 0 ? openNewWorkflowModal : undefined} 
        triggerRefetch={triggerRefetch} />
            {quizSet.length === 0 && <div className="flc-empty">
                
                    <div className="btn feedback" onClick={openNewWorkflowModal}>Create a set</div>
                
            </div>}

        
            {quizSet.length > 0 &&  <div className="qz-internal">
       
                <div
                    className={`qz-content ${animate ? "animate" : ""} ${selectedSet ? "set-selected" : ""
                        }`}>
                    <div className="qz-sets">
                        {selectedSet && (
                            <div onClick={handleBackToSets} className="simple-btn">
                                &larr; Back to Sets
                            </div>
                        )}
                        <div className={`qz-sets-grid ${selectedSet ? "single" : ""}`}>
                            {quizSet.map((qz) => (
                                <div
                                    key={qz.quiz_set_id}
                                    className={`qz-card-item ${qz.quiz_set_id === selectedSet ? "active" : ""
                                        }`}
                                    onClick={() => handleSetClick(qz.quiz_set_id)}
                                    title={qz.title}
                                >
                                      

                                    <div className="qz-item-header">
                                        {workflowRename[qz.quiz_set_id] &&
                                            (<>

                                                <input className='wf-rename'
                                                    value={renameValue[qz.quiz_set_id]}
                                                    type="text"
                                                    onChange={(e) => { handleRenameChange(qz.quiz_set_id, e) }}
                                                    onClick={(e) => { e.stopPropagation() }}

                                                />

                                                <FontAwesomeIcon
                                                    className='wf-rename-confirm'
                                                    icon='fa-solid fa-check'
                                                    onClick={(e) => {e.stopPropagation(); setWorkflowName(qz.quiz_set_id)}}></FontAwesomeIcon>




                                            </>)}
                                        {!workflowRename[qz.quiz_set_id] && <h3 className="qz-item-title">
                                            {qz.title}
                                        </h3>}



                                        <div className="wf-menu-container" onClick={(e) => e.stopPropagation()}>

                                            <FontAwesomeIcon icon='fa-solid fa-ellipsis-vertical'
                                                className='wf-menu-btn'
                                                onClick={(e) => {
                                                    toggleMenu(qz.quiz_set_id)
                                                }}>
                                            </FontAwesomeIcon>



                                            <CSSTransition
                                                in={menuVisible[qz.quiz_set_id]}
                                                timeout={300}
                                                classNames="menu-transition"
                                                unmountOnExit
                                            >
                                                <div className="item-menu" >
                                                    <ul>
                                                        <li onClick={(e) => { e.stopPropagation(); toggleRename(qz.quiz_set_id) }}>
                                                            Rename
                                                        </li>
                                                        {!itemDelete[qz.quiz_set_id] && <li onClick={() => deleteItem(qz.quiz_set_id)}>Delete</li>}
                                                        {itemDelete[qz.quiz_set_id] && <li className='confirm-delete'>Sure?
                                                            {!deletingWorkflow[qz.quiz_set_id] && <div className="wf-delete-controls">
                                                                <FontAwesomeIcon
                                                                    className='wf-delete-control wf-delete-confirm'
                                                                    icon='fa-solid fa-check'
                                                                    onClick={() => deleteWorkflowConfirm(qz.quiz_set_id,)}></FontAwesomeIcon>
                                                                <FontAwesomeIcon
                                                                    className='wf-delete-control wf-delete-cancel'
                                                                    icon='fa-solid fa-close'
                                                                    onClick={() => deleteWorkflowCancel(qz.quiz_set_id,)}></FontAwesomeIcon>
                                                            </div>}
                                                            {deletingWorkflow[qz.quiz_set_id] && <div className="loader"></div>}
                                                        </li>}
                                                    </ul>
                                                </div>

                                            </CSSTransition>
                                        </div>
                                    </div>
                                    
                                    <div className="qz-item-display">
                                        {loading[qz.quiz_set_id] ? (
                                            <div className="loader"></div>  // Display loader while fetching the thumbnail
                                        ) : (
                                            <img
                                                src={thumbnails[qz.quiz_set_id] || '/bookset.jpg'}  // Fallback in case the thumbnail is missing
                                                alt={qz.title}
                                            />
                                        )}
                                    </div>
                                </div>
                                
                            ))}
                        </div>
                    </div>

                    {selectedSet && result === null && (

                        <div className="qz-set">
                            <div className="qz-navigation">
                                <div onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}
                                    className={`${currentQuestionIndex === 0 ? 'btn-disabled' : 'simple-btn'}`}>
                                    &larr; Previous
                                </div>
                                {currentQuestionIndex < quizzes.length - 1 ? (
                                    <div onClick={handleNextQuestion} className="simple-btn">Next &rarr;</div>
                                ) : (
                                    <div onClick={handleFinishQuiz} className="simple-btn"> Finish</div>
                                )}
                            </div>
                            <h2 className="qz-question">{quizzes[currentQuestionIndex].question}</h2>

                            <div className="qz-options">
                                {quizzes[currentQuestionIndex].options.map((option) => (
                                    <div
                                        key={option.option_id}
                                        className={`qz-option ${userAnswers[currentQuestionIndex] === option.option_id ? "selected" : ""}`}
                                        onClick={() => handleAnswerSelection(option.option_id)}
                                    >
                                        {option.option_text}
                                    </div>
                                ))}
                            </div>


                        </div>
                    )}
                    {selectedSet && result !== null && (
                        <div className="qz-result">
                           
                            <div className="score-heading">
                                 <h2>You got <span className="score">{result}</span> correct out of {quizzes.length} </h2>
                             </div>


                            {quizzes && quizzes.map((quiz, index) => (
                                <div key={quiz.quiz_id} className="qz-question">
                                    <h3>{quiz.question}</h3>
                                    <div className="qz-options">
                                        {quiz.options.map((option) => (
                                            <div
                                                key={option.option_id}
                                                className={`qz-option ${userAnswers[index] === option.option_id ? "selected" : ""} ${option.is_correct ? "correct" : ""} `}
                                            >
                                                {option.option_text}
                                            </div>
                                        ))}
                                    </div>
                                   
                                </div>
                            ))
                            }
                            


                            
                        </div>
                    )}

                </div>
            </div>}
        </div>
    );
};

export default Quizzes;
