// src/Register.js

import React, { useState } from 'react';
import api from '../../services/api'
import './Register.css';
import { useNavigate } from 'react-router-dom';

const Register = ({setErrorMsg}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


  
  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await api.post('/register', { email, password, name });
      setMessage('Registration successful');


      setErrorMsg('Verification email has been sent to the email address provided');
      console.log('response', 'this is deductiv emplementer');
      setTimeout(() => {
        setErrorMsg(undefined);
      }, 5000);

      navigate('/auth/login');

     

      setLoading(false);
    } catch (error) {
      setMessage('Registration failed');
      
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="central-container">

     
      <form onSubmit={handleRegister}>
        <div className='input-container'>

          <input
            className='input-field'
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder=" "
          />
          <label className='input-label'>Name</label>
        </div>
        <div className='input-container'>

          <input
            className='input-field'
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder=" "
          />
          <label className='input-label'>Email</label>
        </div>
        <div className='input-container'>

          <input
            className='input-field'
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder=" "
          />
          <label className='input-label'>Password</label>
        </div>
        <div className='input-container'>

          <input
            className='input-field'
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder=" "
          />
          <label className='input-label'>Confirm Password</label>
        </div>

        <div class="options-row">

          <div class="remember-me">
            <div className='login' > Already have an account? <span className="redirect-btn" onClick={() => { navigate('/auth/login') }} >Login</span> </div>
          </div>

     

        </div>

        <button type="submit" class='auth-submit'> {loading && <div class='loader'></div>}Register</button>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
};

export default Register;
