import { react, useState, useEffect } from 'react'
import api from '../../../services/api'

import YouTube from 'react-youtube';
import './VideoDisplay.css'


const VideoDisplay = ({ documentId }) => {

    const [transcript, setTranscript] = useState(undefined);
    const [videoId, setYoutubeId] = useState(undefined);
    const [transcriptLoading, setTranscriptLoading] = useState(undefined);
    const [player, setPlayer] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [activeTranscriptIndex, setActiveTranscriptIndex] = useState(-1);

    const onPlayerReady = (event) => {
        setPlayer(event.target);
    };



    const onPlayerStateChange = () => {
        if (player) {
            // Update current time every second
            const interval = setInterval(() => {
                const currentVideoTime = player.getCurrentTime();
                setCurrentTime(currentVideoTime);
            }, 1000);

            // Clear interval when the component unmounts
            return () => clearInterval(interval);
        }
    };

    const getActiveTranscriptIndex = () => {
        if (!transcript) return -1;


        for (let i = 0; i < transcript.length; i++) {

            if (currentTime < transcript[i].start_time) {
                
              
                return i - 1;
            }
        }


    };

    const updateActiveTranscriptIndex = () => {
        const newActiveIndex = getActiveTranscriptIndex();
        // console.log(currentTime);
        // console.log(newActiveIndex);

        if (newActiveIndex !== activeTranscriptIndex) {
            setActiveTranscriptIndex(newActiveIndex);
        }
    };

    useEffect(() => {
        updateActiveTranscriptIndex();
    }, [currentTime]);



    const fetchYoutubeUrl = async (documentId) => {
        try {
            const response = await api.get('/get-youtube-url', {
                params: { document_id: documentId },

            })
            let url = response.data;
            console.log('new url', url);
            fetchTranscript(documentId);
            setYoutubeId(url.split('/')[1])

        } catch (error) {

        }
    }

    const fetchTranscript = async (documentId) => {
        setTranscriptLoading(true)

        try {

            const response = await api.get('/get-transcript', {
                params: { document_id: documentId },
            })

            setTranscript(response.data)
            setTranscriptLoading(false)


            return response.data.transcript
        } catch (error) {

        }
    }

    const handleTranscriptClick = (time) => {
        if (player) {
            player.seekTo(time, true); // 'true' for allowing seeking ahead of buffer
        }
    };




    useEffect(() => {
        console.log('changing doc', documentId)
        fetchYoutubeUrl(documentId);

    }, [documentId])

    return (
        <div className='video-player'>

            <div className="video-responsive">
                <YouTube
                    key={videoId}
                    videoId={videoId}
                    onReady={onPlayerReady}
                    onStateChange={onPlayerStateChange}
                    opts={{
                        playerVars: {
                            autoplay: 0,
                        },
                    }}
                />
            </div>

            <div className='display-transcript'>
                {transcriptLoading && <div> loading...</div>}

                {transcript && transcript.map((t, i) =>
                    transcriptLoading ? <div> loading...</div>
                        :
                        (<div className={`transcript-item ${i === activeTranscriptIndex ? 'active' : ""}`}
                            onClick={() => { console.log(t.start_time); handleTranscriptClick(t.start_time) }}
                            key={t.start_time} >
                            <div className="timestamp">{`${parseInt(parseInt(t.start_time) / 60)}:${parseInt(t.start_time % 60).toString().padStart(2, '0')}`} </div>
                            <div className="transcript-text">{t.text} </div>
                        </div>)
                )}
            </div>

        </div >
    )

}

export default VideoDisplay;