// Chatbot.js
import React, { useEffect, useRef, useState } from 'react';
import useChatbot from '../../hooks/useChatbot';
import './Chatbot.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import MarkdownRenderer from '../common/MarkdownRenderer';
import Logo from '../common/logo';
import { useApp } from '../../contexts/AppContext';
const apiUrl = process.env.REACT_APP_API_URL;

const Chatbot = ({ className,
  notesVisible,
  toggleNotes,
  togglePodcasts,
  objectId,
  objectType,
  onReferenceClick 


}) => {
  const {
    messages,
    setMessages,
    loading,
    setLoading,
    inputValue,
    setInputValue,
    setObjectId,
    setObjectType
  } = useChatbot();

  const messagesEndRef = useRef(null);
  const { useOpenDyslexic, toggleFont } = useApp();


  // State to hold the current streaming assistant message
  const [currentAssistantMessage, setCurrentAssistantMessage] = useState('');

  // Scroll to the bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Update objectId and objectType when they change
  useEffect(() => {
    setObjectId(objectId);
    setObjectType(objectType);
  }, [objectId, objectType, setObjectId, setObjectType]);

  // Effect to update the last assistant message when currentAssistantMessage changes
  useEffect(() => {
    if (currentAssistantMessage === '') return; // Avoid updating on initial render

    setMessages(prevMessages => {
      // Find the last message from the assistant
      const lastIndex = prevMessages.length - 1;
      const lastMessage = prevMessages[lastIndex];

      // If the last message is from the assistant, update its text
      if (lastMessage && lastMessage.sender === 'assistant') {
        const updatedMessage = {
          ...lastMessage,
          text: currentAssistantMessage
        };
        const newMessages = [...prevMessages];
        newMessages[lastIndex] = updatedMessage;
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        return newMessages;
      } else {
        // If there's no assistant message, append a new one
        return [...prevMessages, { text: currentAssistantMessage, sender: 'assistant', isMarkdown: true }];
      }
    });
  }, [currentAssistantMessage, setMessages]);

  const sendMessage = async () => {
    if (!inputValue.trim()) return;

    const userMessage = inputValue.trim();
    // Add user message to messages
    setMessages(prevMessages => [...prevMessages, { text: userMessage, sender: 'user' }]);
    setInputValue('');
    setCurrentAssistantMessage(''); // Reset current assistant message

    try {
      const response = await fetch(`${apiUrl}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/event-stream',
        },
        body: JSON.stringify({
          sender: 'user',
          text: userMessage,
          object_type: objectType,
          object_id: objectId,
        }),
      });

      if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // Append an empty assistant message to start streaming
      setMessages(prevMessages => [...prevMessages, { text: '', sender: 'assistant', isMarkdown: true }]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('<ld>');

        for (const line of lines) {

          if (line.startsWith('data: ')) {
            const data = line.slice(6);

            if (data === '[DONE]') {
              // Stream has finished
              console.log(currentAssistantMessage)
              return;
            } else if (data.startsWith('Error:')) {
              console.error(data);
              setMessages(prevMessages => [
                ...prevMessages,
                { text: data, sender: 'system', isError: true }
              ]);
            } else {
              // Append data to the current assistant message
              setCurrentAssistantMessage(prev => prev + data);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'An error occurred while sending your message.', sender: 'system', isError: true }
      ]);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  if (loading)
    return(
      <div className="chatbot-loading">
        <div className='loader-pulse'></div>
      </div>
    )

  return (
    <div className={` chatbot ${className} ${useOpenDyslexic?'large':''}`}>
      <div className="controls">
        <div onClick={togglePodcasts}>
          <FontAwesomeIcon icon="fa-solid fa-podcast" className='icon-btn' />
        </div>
        <div onClick={toggleNotes}>
          <FontAwesomeIcon icon="fa-solid fa-pencil" className='icon-btn' />
        </div>

      </div>
      <div className="chatbot-area">
        {messages.length === 0 && <div className="ch-logo-container">
          <div className='ch-logo'><Logo></Logo></div>
        </div>
        }
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={`${message.sender}-${index}`} className={`message ${message.sender}`}>
              <div className={`identifier-${message.sender}`}></div>

              <div className="message-item">
                <div className="sender">{message.sender === 'user' ? 'You' : 'Deductiv'}</div>
                <MarkdownRenderer
                  onReferenceClick={onReferenceClick}
                  markdownText={message.text}
                ></MarkdownRenderer>
                {/* <ReactMarkdown
                  className='message-text'
                  key={index}
                >
                  {message.text}
                </ReactMarkdown> */}
              </div>
            </div>
          ))}
          <div className='end' ref={messagesEndRef}></div>
        </div>
        <div className="input-area">
          <input
            className='input-textbox'
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type your message..."
          />
          <button onClick={sendMessage}>
            <FontAwesomeIcon icon="fa-solid fa-paper-plane" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
