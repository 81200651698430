import React, { useContext } from 'react';
import './TopBar.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import Feedback from './Feedback';
import { AppContext, useApp } from '../../contexts/AppContext';



const TopBar = ({ navPaneExtended, toggleNavPane, checkAuth, title, newBtnClick, triggerRefetch }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { useOpenDyslexic, toggleFont } = useApp();

  const openFeedbackModal = () => {
    dispatch(openModal({
      component: Feedback,
      props: {}
    }));
  };



  const handleLogout = async () => {
    try {
      // Send a request to the logout endpoint
      await api.post('/logout', {}, {
        withCredentials: true,
      });
      await checkAuth();

      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      localStorage.clear();
      triggerRefetch();
      navigate('/');

    } catch (error) {
      console.error("Failed to logout", error);
    }
  };

  return (


    <div className="top-bar">
      <div className="title">
        {!navPaneExtended && <FontAwesomeIcon onClick={() => { toggleNavPane(true) }} icon="fa-solid fa-bars" className='icon-btn' />}
        <h2>{title}</h2>
        {
          newBtnClick &&
          <div className="btn feedback" onClick={newBtnClick}>Create a set</div>
        }
      </div>
      <div className="btns">
        <div className="font-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={useOpenDyslexic}
              onChange={toggleFont}
            />
            <span className="slider round"></span>
          </label>

        </div>

        <div className="feedback btn" onClick={openFeedbackModal}>Feedback</div>

        <div className="logout btn" onClick={handleLogout}>Logout</div>
      </div>
    </div>
  );
}

export default TopBar;