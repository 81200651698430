import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin, RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import YouTube from 'react-youtube';


import './DocumentViewer.css';
import api from '../../services/api';
import VideoDisplay from './DocumentDisplay/VideoDisplay';
import FileDisplay from './DocumentDisplay/FileDisplay';
import { useDocumentsApp } from '../../contexts/DocumentContext';

const DocumentViewer = forwardRef(({ className, setTitle }, ref) => {
  const [fileUrls, setFileUrls] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const viewerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const playerRef = useRef(null);

  const {
    objectType,
    objectId,
    selectedWorkflow: currentWorkflow,
    setSelectedDocument,
    selectedDocument: currentDocument,
  } = useDocumentsApp();



  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const highlightPluginInstance = highlightPlugin();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [underlineStyle, setUnderlineStyle] = useState({});
  const { jumpToPage } = pageNavigationPluginInstance;
  const [transcripts, setTranscripts] = useState({});
  const [loadingTranscripts, setLoadingTranscripts] = useState({});

  const [player, setPlayer] = useState(null);

  // Function to handle when the player is ready
  const onPlayerReady = (event) => {
    setPlayer(event.target);
  };

  const onPlayerStateChange = () => {
    if (player) {
      // Update current time every second


      console.log(player.getCurrentTime());

      // Clear interval when the component unmounts

    }
  };




  useImperativeHandle(ref, () => ({
    scrollToPage: (pageNumber) => {


      if (jumpToPage) {

        jumpToPage(pageNumber - 1);  // PDF page numbers are 0-based
      }
    }
  }));

  useEffect(()=>{
    setActiveTabIndex(0);
  }, [currentWorkflow])


  useEffect(() => {
    const handleFetch = async () => {

      console.log(objectType);
      const url = undefined;

      if (objectType === 'document' && objectId) {


        let url = await fetchDocument(objectId);

        setLoading(false);



        if (url) {
          setFileUrls([url]);
        }
      } else if (objectType === 'workflow' && currentWorkflow) {
        setTitle(currentWorkflow.name);
        const urls = await Promise.all(
          currentWorkflow?.documents.map((doc) => {


            if (doc.document_type !== 'youtube')
              return fetchDocument(doc.document_id)
            else {

              return undefined;
            }
          }
          )
        );
        setFileUrls(urls.filter(Boolean));
        setLoading(false);
      }
    };
    try {
      handleFetch();
    } catch (e) {
      console.log(e)
    }

  }, [objectType, objectId, currentDocument, currentWorkflow])

  





  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className="custom-toolbar">
            <div className="left-section">
              <GoToPreviousPage />
              <CurrentPageInput /> of <NumberOfPages />
              <GoToNextPage />
            </div>
            <div className="right-section">
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  // const customLayoutPlugin = defaultLayoutPlugin({
  //   renderToolbar,
  //   // renderGoToPreviousPage,
  //   // renderGoToNextPage,
  // });

  const customLayoutPlugin = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => defaultTabs.filter((tab) => tab.title !== 'Attachment'),
  });



  const scrollLeft = () => {
    const container = document.querySelector('.tabs-container');
    if (container) {
      container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    const container = document.querySelector('.tabs-container');
    if (container) {
      container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
    }
  };


  useEffect(() => {
    
    const activeTab = document.querySelector('.tab.active');
    const tabsContainer = document.querySelector('.tabs-container');
    if (activeTab && tabsContainer) {
      const containerRect = tabsContainer.getBoundingClientRect();
      const tabRect = activeTab.getBoundingClientRect();

      setUnderlineStyle({
        left: (tabRect.left - containerRect.left + tabsContainer.scrollLeft) + 'px',
        width: tabRect.width + 'px',
      });

      // Scroll to make the active tab visible
      activeTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [activeTabIndex]);

  const selectDocFromTab = async () =>{

  }



  const fetchDocument = async (documentId) => {
    try {
      const response = await api.get('/document', {
        params: { document_id: documentId },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

    
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

 


  useEffect(() => {
    const handleFetch = async () => {




      if (objectType === 'document' && objectId) {

        let url;

        if (currentDocument.document_type !== 'youtube') {
          url = await fetchDocument(objectId);
        }
        else {
          url = undefined;
        }

        // setLoading(false);



        if (url) {
          setFileUrls([url]);
        }
      } else if (objectType === 'workflow' && currentWorkflow) {
        setTitle(currentWorkflow.name);
        const urls = await Promise.all(
          currentWorkflow?.documents.map((doc) => {

            if (doc.document_type !== 'youtube')
              return fetchDocument(doc.document_id)
            else {

              return undefined
            }
          }
          )
        );
        setFileUrls(urls.filter(Boolean));
        setLoading(false);
      }
    };
    try {
      handleFetch();
    } catch (e) {
      console.log(e)
    }
  }, [objectId, objectType, currentWorkflow, currentDocument]);

  const handleTranscriptClick = (time) => {
    if (player) {
      player.seekTo(time, true); // 'true' for allowing seeking ahead of buffer
    }
  };


  if (loading) {
    return (
      <div>
        Loading...
      </div>
    )
  }



  return (
    <div className={` document-viewer ${className}`}>
     

            {objectType === 'workflow' && <div className="tabs-container">

            <div className="tabs">
              {currentWorkflow.documents.map((doc, index) => {

                return <button
                title={doc.titl}
                  key={doc.document_id}
                  className={`tab ${index === activeTabIndex ? 'active' : ''}`}
                  onClick={()=>{setSelectedDocument(doc); setActiveTabIndex(index)}}
                >
                  {doc.title}
                </button>
              }

              )}
              
            </div>

          </div>}


    
  
         {currentDocument && 
         <>
          {currentDocument.document_type !== 'youtube' && (
            <FileDisplay documentId={currentDocument.document_id}></FileDisplay>
          )}

          {currentDocument.document_type === 'youtube' &&

            <VideoDisplay documentId={currentDocument.document_id}></VideoDisplay>

          }
        </>
        }

      
    </div>
  );
});

export default DocumentViewer;
