import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import './VerifyEmail.css'; // Import the CSS file

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Function to extract query parameter from URL
  const getTokenFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get('token');
  };

  // Make verification API call
  const verifyEmail = async (token) => {
    try {
      // Replace this URL with your actual API endpoint
      const response = await api.get(`/verify-email?token=${token}`);
      setMessage('Verification successful! Redirecting to login...');
      setLoading(false);
      // Redirect to /auth/login after 2 seconds
      setTimeout(() => {
        navigate('/auth/login');
      }, 2000);
    } catch (err) {
      setError(true);
      setMessage('Verification failed or token is invalid.');
      setTimeout(() => {
        navigate('/auth/login');
      }, 2000);
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = getTokenFromURL();
    if (token) {
      verifyEmail(token);
    } else {
      setError(true);
      setMessage('Invalid or missing token.');
      setTimeout(() => {
        navigate('/auth/login');
      }, 2000);
      setLoading(false);
    }
  }, []);

  return (
    <div className="verify-container">
      {loading ? (
        <>
          <div className="spinner"></div>
          <p className="loading-text">Verifying your email, please wait...</p>
        </>
      ) : error ? (
        <p className="error-text">{message}</p>
      ) : (
        <p className="success-text">{message}</p>
      )}
    </div>
  );
};

export default VerifyEmail;
