import React, { useEffect, useRef } from 'react';
import { Markmap } from 'markmap-view';
import { Transformer } from 'markmap-lib';

const transformer = new Transformer();

const MarkMapComponent = ({ markdownContent }) => {
  const svgRef = useRef(null);
  const mmRef = useRef(null);

  useEffect(() => {
    if (svgRef.current) {
      const { root, features } = transformer.transform(markdownContent);
      
      if (!mmRef.current) {
        mmRef.current = Markmap.create(svgRef.current);
      }
      
      mmRef.current.setData(root);
      mmRef.current.setOptions({ ...mmRef.current.options, ...features });
    }
  }, [markdownContent]);

  return (
    <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />
  );
};

export default MarkMapComponent;