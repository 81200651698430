import React, { useEffect, useState, useRef } from 'react';
import Logo from '../common/logo';
import useWorkflows from '../../hooks/useWorkflows';
import { useNavigate } from 'react-router-dom';
import './NavigationPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewWorkflow from './NewWorkflow';
import DocumentUpload from './DocumentUpload';
import { CSSTransition } from 'react-transition-group';
import Settings from './Settings';
import Founders from './Founders';
import { useApp, useSelectedItems } from '../../contexts/AppContext';
import api from '../../services/api';
import { useDocumentsApp } from '../../contexts/DocumentContext';


const NavigationPanel = ({ paneExtended,
  togglePane,
  setCurrentWorkflowId,
  currentWorkflowId,
  setCurrentDocumentId,
  toggleNewWorkflowModal,
  workflows,
  setWorkflows,
  expandedWorkflows,
  toggleWorkflow,
  refetchTrigger,
  triggerRefetch,
  setNewDocumentWorkflowId,
  setCurrentWorkflow }) => {

  // let [selectedWorkflow, setSelectedWorkflow] = useState(currentWorkflowId); 
  const {
    setSelectedWorkflowId,
    setSelectedDocumentId,
    selectedWorkflowId,
    selectedDocumentId
  } = useApp();

  const {
    setObjectType,
    objectType,
    setObjectId,
    setSelectedWorkflow,
    setSelectedDocument,
    selectedDocument: currentDocument,
    selectedWorkflow: currentWorkflow
  } = useDocumentsApp();

  const { selectedWorkflow, selectedDocument } = useSelectedItems();
  const [menuVisible, setMenuVisible] = useState({});
  const [hoveredWorkflow, setHoveredWorkflow] = useState(null);
  const [workflowRename, setWorkflowRename] = useState({})
  const [renameValue, setRenameValue] = useState({});
  const [workflowDelete, setWorkflowDelete] = useState({})
  const [deletingWorkflow, setDeleteingWorkflow] = useState({})
  const renameComponent = useRef()



  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleWorkflowClick = (workflowId, index) => {
    // setSelectedWorkflow(workflowId);

 
    setObjectType('workflow')
    setObjectId(workflowId)
    console.log(workflows)
    let wf = workflows.filter(w => w.id === workflowId)[0]
    console.log('filtered wf:', wf)
    setSelectedWorkflow(wf);
    setSelectedDocument(wf.documents[0]);
    setCurrentWorkflowId(workflowId);
    setCurrentWorkflow(workflows[index]);
    setCurrentDocumentId(undefined);
    navigate(`/w/workflow/${workflowId}`);
  }


  const handleDocumentClick = (documentId) => {
    setObjectType('document')
    setObjectId(documentId)


    workflows.forEach(wf=>{
      wf.documents.forEach(d=>{
        if(d.document_id === documentId){
          setSelectedWorkflow(wf);
          setSelectedDocument(d);
        }
      })
    })
    
    setCurrentDocumentId(documentId);
    setCurrentWorkflowId(undefined);
    // setCurrentWorkflow(undefined);
  }


  // const handleNewWorkflowClick = () => {
  //   toggleNewWorkflowModal();
  // }

  const openNewWorkflowModal = () => {
    dispatch(openModal({
      component: NewWorkflow,
      props: { onUploadComplete: triggerRefetch }
    }));
  };

  const openNewDocumentModal = (workflowId) => {
    dispatch(openModal({
      component: DocumentUpload,
      props: { onUploadComplete: triggerRefetch, currentWorkflowId: workflowId }
    }));
  };

  const openSettingsModal = (workflowId) => {
    dispatch(openModal({
      component: Settings,
      props: {}
    }));
  };

  const openFoundersModal = (workflowId) => {

    dispatch(openModal({
      component: Founders,
      props: {}
    }));

  };



  const handleNewDocument = (workflowId) => {

    setMenuVisible(false)
    openNewDocumentModal(workflowId);
  }

  const toggleMenu = (workflowId) => {
    setMenuVisible((prevState) => ({
      ...prevState,
      [workflowId]: !prevState[workflowId],
    }));
  };

  const toggleRename = (workflowId) => {
    setMenuVisible(false);


    setRenameValue((prevState) => ({
      ...prevState,
      [workflowId]: workflows.find(e => e.id === workflowId).name
    }))
    setWorkflowRename((prevState) => ({
      ...prevState,
      [workflowId]: true
    }))
  }

  const setWorkflowName = async (workflowId) => {

    setWorkflowRename((prevState) => ({
      ...prevState,
      [workflowId]: false
    }))

    //  console.log(workflows)

    setWorkflows((prevState) =>
      prevState.map((workflow) =>
        workflow.id === workflowId
          ? { ...workflow, name: renameValue[workflowId] } // Update the name if the ID matches
          : workflow // Keep the workflow unchanged if the ID doesn't match
      )
    );

    const response = await api.post(`/rename-workflow/${workflowId}`, {}, {
      params: { 'new_name': renameValue[workflowId] }
    })


  }


  const handleRenameChange = (workflowId, event) => {
    // Update the input value for the specific workflow

    setRenameValue({ ...renameValue, [workflowId]: event.target.value });
  };


  const deleteWorkflow = (workflowId) => {
    console.log(workflowId);
    setWorkflowDelete({ ...workflowDelete, [workflowId]: true })

  }

  const deleteWorkflowCancel = (workflowId)=>{
    setWorkflowDelete({...workflowDelete, [workflowId]: false});
  }



  const deleteWorkflowConfirm =  async (workflowId) => {
    console.log('current workflow', currentWorkflow);
    let cwfId = currentWorkflow.id;
    let cwfIdIndex = workflows.findIndex(wf => wf.id === cwfId)
    
   
    

    setDeleteingWorkflow((prevState)=>({
      ...prevState,
      [workflowId]: true
    }))

    let response = await api.post(`/delete-workflow/${workflowId}`);
    console.log(response);
    setDeleteingWorkflow((prevState)=>({
      ...prevState,
      [workflowId]: false
    }))

    triggerRefetch();
    if (currentWorkflow.id === workflowId)
      navigate('/w/new');
    


  }







  return (
    <div className={`navigation-panel ${paneExtended ? 'extended' : 'collapsed'}`}>


      <div className="logo-and-toggle">
        <Logo />
        <div className="toggle" onClick={() => { togglePane(false) }}>
          <FontAwesomeIcon icon="fa-solid fa-bars" className='icon-btn' />
        </div>
      </div>


      <div className="workflow-and-new">
        <div className='nav-itm' onClick={() => openNewWorkflowModal()}>
          <FontAwesomeIcon icon='fa-solid fa-add' className='add-workflow' >
          </FontAwesomeIcon>New Workflow
        </div>

        <div className="workflows">
          <ul>
            {workflows
              .sort((a, b) => b.id - a.id)
              .map((workflow, index) => (
                <li key={workflow.id}>

                  {(
                    <>
                      <div
                        onMouseEnter={() => setHoveredWorkflow(workflow.id)}
                        onMouseLeave={() => { setMenuVisible(false); setHoveredWorkflow(null) }}
                        className={`nav-itm ${(currentWorkflow && currentWorkflow.id === workflow.id) ? 'wf-selected' : ''}`}>

                        
                        {workflowRename[workflow.id] &&
                          (<><input className='wf-rename'
                            value={renameValue[workflow.id]}
                            type="text"
                            onChange={(e) => {handleRenameChange(workflow.id, e)}}
                          />
                         

                          </>)}
                        {!workflowRename[workflow.id] && <span
                          className="nav-text nav-itm-itm"
                          onClick={() => handleWorkflowClick(workflow.id, index)} >
                          {workflow.name}
                        </span>}
                        


                        <div className="wf-menu-container">
                          {!workflowRename[workflow.id] && <CSSTransition
                            in={hoveredWorkflow === workflow.id || menuVisible[workflow.id] || selectedWorkflow === workflow.id}
                            timeout={300}
                            classNames="menu-btn-transition"
                            unmountOnExit
                          >
                            <FontAwesomeIcon icon='fa-solid fa-ellipsis-vertical'
                              className='wf-menu-btn'
                              onClick={() => toggleMenu(workflow.id)}>
                            </FontAwesomeIcon>
                          </CSSTransition>}
                          {workflowRename[workflow.id]
                            &&
                            <FontAwesomeIcon
                              className='wf-rename-confirm'
                              icon='fa-solid fa-check'
                              onClick={() => setWorkflowName(workflow.id,)}></FontAwesomeIcon>}


                          <CSSTransition
                            in={menuVisible[workflow.id]}
                            timeout={300}
                            classNames="menu-transition"
                            unmountOnExit
                          >
                            <div className="wf-menu">

                              <ul>
                                <li
                                  onClick={(e) => handleNewDocument(workflow.id)}
                                >New</li>
                                <li
                                  onClick={() => { console.log(workflow.id); toggleRename(workflow.id); console.log(workflowRename) }}
                                >Rename</li>
                                {!workflowDelete[workflow.id] && <li onClick={() => deleteWorkflow(workflow.id)}>Delete</li>}
                                {workflowDelete[workflow.id] && <li className='confirm-delete'>Sure?
                                  {!deletingWorkflow[workflow.id] && <div className="wf-delete-controls">
                                    <FontAwesomeIcon
                                    className='wf-delete-control wf-delete-confirm'
                                    icon='fa-solid fa-check'
                                    onClick={() => deleteWorkflowConfirm(workflow.id,)}></FontAwesomeIcon>
                                    <FontAwesomeIcon
                                    className='wf-delete-control wf-delete-cancel'
                                    icon='fa-solid fa-close'
                                    onClick={() => deleteWorkflowCancel(workflow.id,)}></FontAwesomeIcon>
                                  </div>}
                                  {deletingWorkflow[workflow.id] && <div className="loader"></div>} 
                                </li>}
                              </ul>
                            </div>
                          </CSSTransition>

                        </div>
                        <div className="nv-icon">
                          <FontAwesomeIcon icon="fa-solid fa-angle-right"
                            className={`nav-itm-itm ${expandedWorkflows[workflow.id] ? 'rotated' : ''}`}
                            onClick={() => toggleWorkflow(workflow.id)}
                          />
                        </div>



                      </div>
                      <div>
                        <CSSTransition
                          in={expandedWorkflows[workflow.id]}
                          timeout={200}
                          classNames="list"
                          unmountOnExit
                        >
                          <ul className="document-list">
                            {workflow.documents.map((document) => (
                              <li
                                key={document.document_id}
                                onClick={() => handleDocumentClick(document.document_id)}
                              >
                                <div className={`nav-itm inner-itm nav-itm-itm ${(objectType  === 'document' && currentDocument && currentDocument.document_id === document.document_id) ? 'wf-selected' : ''} `} >
                                  <span className="nav-text">{document.title}</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </CSSTransition>
                      </div>
                    </>)
                  }
                </li>
              ))}
          </ul>
        </div>

      </div>

      <div className="navigation-items">
        <ul>
          <li>
            <div className="nav-itm" onClick={() => {setSelectedDocument(undefined); setSelectedWorkflow(undefined) ;navigate('/w/flashcards') }}>
              <FontAwesomeIcon icon="fa-solid fa-clone" />
              <span className="nav-text">Flashcards</span></div>
          </li>
          <li>
            <div className="nav-itm" onClick={() => {setSelectedDocument(undefined); setSelectedWorkflow(undefined);  navigate('/w/quizzes') }}>
              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
              <span className="nav-text">Quizzes</span>
            </div>
          </li>
          <li>
            <div className="nav-itm" onClick={()=>{; setSelectedDocument(undefined); setSelectedWorkflow(undefined);navigate('/w/mindmaps')}}>
              <FontAwesomeIcon icon="fa-solid fa-arrows-turn-to-dots" />
              <span className="nav-text">Mindmaps</span></div>
          </li>
        </ul>
      </div>
      <div className="settings">
        <ul>
          <li>
            <div className="nav-itm" onClick={openSettingsModal}>
              <FontAwesomeIcon icon='fa-solid fa-gear'></FontAwesomeIcon>
              <span className="nav-text">Settings</span>
            </div>
          </li>
          {/* <li>
            <div className="nav-itm">
              <FontAwesomeIcon icon='fa-solid fa-palette'></FontAwesomeIcon>
              <span className="nav-text">Theme</span>
            </div>
          </li> */}
          <li>
            <div className="nav-itm" onClick={openFoundersModal}>
              <FontAwesomeIcon icon='fa-solid fa-phone'></FontAwesomeIcon>
              <span className="nav-text">Talk to the team</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavigationPanel;
