import { react, useState, useRef, useEffect } from 'react'
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin, RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import './FileDisplay.css'

import api from '../../../services/api'

const FileDisplay = ({ documentId }) => {

    const viewerRef = useRef(null);

    const [fileUrl, setFileUrl] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const pageNavigationPluginInstance = pageNavigationPlugin();

    const fetchDocument = async (documentId) => {
        try {
          const response = await api.get('/document', {
            params: { document_id: documentId },
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          });
    
        
          return URL.createObjectURL(response.data);
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };


    useEffect(()=>{
        const handleFetch = async () =>{
            setLoading(true);
            let url = await fetchDocument(documentId);
            setLoading(false);
            if (url) setFileUrl(url);

        }

        try {

            
            handleFetch();
           
          } catch (e) {
            console.log(e)
          }

        
    }, [documentId])






    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = slots;
                return (
                    <div className="custom-toolbar">
                        <div className="left-section">
                            <GoToPreviousPage />
                            <CurrentPageInput /> of <NumberOfPages />
                            <GoToNextPage />
                        </div>
                        <div className="right-section">
                            <ZoomOut />
                            <Zoom />
                            <ZoomIn />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );


    const customLayoutPlugin = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => defaultTabs.filter((tab) => tab.title !== 'Attachment'),
    });

    if(loading){
        return(
            <div className="file-loading">
                <div className='loader-pulse'></div>
            </div>
        )
    }

    return (
        <>
        {fileUrl && <div className="pdf-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[
                        // defaultLayoutPluginInstance,
                        // highlightPluginInstance,
                        // zoomPluginInstance,
                        customLayoutPlugin,
                        pageNavigationPluginInstance
                    ]}
                    ref={viewerRef}
                    defaultScale={SpecialZoomLevel.PageFit}
                />
            </Worker>
        </div>}
        </>
    )

}

export default FileDisplay;