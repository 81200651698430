import React, { useState, useRef, useEffect } from 'react';
import './Initiation.css'; // This is the same CSS file we used before, now adapted for your requirements
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';


const Initiation = ({ onUploadComplete,
    navPaneExtended,
    toggleNavPane,
    checkAuth,
    triggerRefetch,
}) => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [innerWorkflowId, setWorkflowId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [youtubeLink, setYoutubeLink] = useState('');
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const allowedExtensions = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

        const newFiles = Array.from(event.target.files).filter(file => {
            // Check if the file type is allowed
            if (!allowedExtensions.includes(file.type)) {
                setErrorMessage('File extension not allowed')
                setTimeout(() => {
                    setErrorMessage(undefined);
                }, 5000);
                return false;
            }
            return true;
        }).map(file => ({
            file,
            name: file.name,
            fileType: 'file',
            size: (file.size / (1024 * 1024)).toFixed(2), // size in MB
            progress: 0
        }));

        // If valid files exist, update the state
        if (newFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }

        // Reset the input value to allow re-uploading the same file
        event.target.value = null;
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {

                const response = await api.get('/get-user'); // Adjust this endpoint as needed
                setName(response.data.name);
               

            } catch (error) {
                console.error('Failed to fetch user data:', error);

            } finally {

            }
        };

        fetchUser();
    }, []);

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files).map(file => ({
            file,
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2), // size in MB
            progress: 0
        }));
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const uploadFiles = async () => {
        let params = new URLSearchParams();
        params.append('workflow_title', 'First Workflow');
        const response = await api.post('/create-workflow', {}, {
            params: params,
        });
        console.log('response', response.data);
        let workflowId = response.data.workflow_id;
        console.log(workflowId)
        // setWorkflowId(response.data.workflow_id);
        setLoading(true);
        for (let i = 0; i < files.length; i++) {

            if (files[i].fileType === 'youtube'){
                let tFile = files[i]
                let vId = tFile.file;
                try{
                    const response = await api.post('/upload-youtube', {'workflow_id': workflowId, 'video_id':vId})
                    console.log(response.data);
                }catch{
                  
                }
            }

            else{

            const formData = new FormData();
            formData.append('file', files[i].file);

            let params2 = new URLSearchParams();
            params2.append('workflow_id', workflowId);



            try {
                const response = await api.post('/upload', formData, {
                    params: params2,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true,
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prevProgress) => ({
                            ...prevProgress,
                            [i]: progress
                        }));
                    }
                });
                console.log('File uploaded:', response);
            } catch (error) {
                console.error('Error uploading file', error);
            }
        }
        }
        triggerRefetch();
        setLoading(false);
        setFiles([]); // Clear the files after upload
        onUploadComplete();
        navigate('/w/workflow/' + workflowId);
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setUploadProgress((prevProgress) => {
            const newProgress = { ...prevProgress };
            delete newProgress[index];
            return newProgress;
        });
    };

    const extractYouTubeID = (url) => {
        const regex = /[?&]v=([^&]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    const addYoutube = async () =>{
        console.log(youtubeLink);
        const videoID = extractYouTubeID(youtubeLink);
        console.log(videoID);

        if (files.some(file => file.file === videoID)) {
            console.log('Video already exists in the list.');
            return;
        }

        try{
            let response = await api.get('/get-youtube-title',{
                params:{
                    video_id: videoID
                }
            })

            setFiles((prevFiles) => [...prevFiles, {
                file: videoID,
                name: response.data,
                size: 0, // size in MB
                fileType: 'youtube',
                progress: 0
            }]);
            console.log(response.data);
        }catch{
            setErrorMessage('Youtube video does not have transcripts enabled');
            setTimeout(() => {
                setErrorMessage(undefined);
            }, 5000);
        }
        
    }



    return (
        <div className="welcome-page">
            <TopBar
                navPaneExtended={navPaneExtended}
                toggleNavPane={toggleNavPane}
                checkAuth={checkAuth}
                title="New Workflow"
                triggerRefetch={triggerRefetch}
            />

            {errorMessage && (
                <div className="error-box">
                    {errorMessage}
                </div>
            )}

            <div className="init-main-area-container">
                <div className="init-main-area">
                    <h1>Welcome to deductiv, <span className="cap"><strong>{name}</strong></span></h1>
                    <p>Start importing your school material and let deductiv make your academic journey a breeze</p>
                    <div className="init-file">
                        <div className="action-container">
                            <div className="action-box import-box"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onClick={() => { fileInputRef.current.click() }}>
                                <FontAwesomeIcon icon='fa-solid fa-upload' />
                                <h2>Import</h2>
                                <p>Add files into your library</p>
                                <p>eBooks, Lecture Slides, Journal Articles</p>
                                <p>PDF, DOC, PPT</p>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="file-input"
                                    ref={fileInputRef}
                                    accept=".pdf, .doc, .docx, .ppt, .pptx"
                                    multiple
                                    hidden
                                />
                            </div>
                            <div className='init-divider'>
                                <div className="init-line"></div>
                                <div className="init-line-desc">OR</div>
                                <div className="init-line"></div>
                            </div>
                            <div className="init-link">
                                <input className='init-link-textbox' type="text" placeholder='Paste a youtube link'  value={youtubeLink}  onChange={(e) => setYoutubeLink(e.target.value)}/>
                                <div className='submit' onClick={()=>addYoutube()}><div className="submit-text">Submit</div></div>
                            </div>
                        </div>
                        <div className="init-staging-area">
                
                            <div className={`staging-files ${files.length === 0 ? 'empty-staging': ''}`}>
                            {files.length === 0 && <DotLottieReact
                                className='empty-animation'
                                src="/Animation - 1730232590703.json"
                                loop
                                autoplay
                            />}
                                {files.map((file, index) => (
                                    <div key={index} className="staged-file">
                                        <div className="file-icon">
                                            {file.fileType === 'file' && <FontAwesomeIcon icon='fa-solid fa-file' />}
                                            {file.fileType === 'youtube' && <FontAwesomeIcon icon="fa-solid fa-video" />}
                                        </div>
                                        <div className="file-info">
                                            <span className='filename'>{file.name}</span>
                                            <span>{file.size} MB</span>
                                            <progress value={uploadProgress[index] || 0} max="100" />
                                            <div
                                                className="remove-btn"
                                                onClick={() => handleRemoveFile(index)}
                                            >
                                                <FontAwesomeIcon icon="fa-solid fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={`init-upload-btn ${files.length === 0 || loading ? 'disabled' : ''}`} onClick={uploadFiles}>{loading && <div className='loader'></div>} Upload</div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    );
};

export default Initiation;
